import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
// import Slider from './../Elements/Slider';
// import Specialization from './../Elements/Specialization';
import About from './../Elements/About';
// import OurValue from './../Elements/OurValue';
import OurMission from './../Elements/OurMission';
// import OurServices from './../Elements/OurServices';
// import Callus from './../Elements/Callus';
// import OurProject from './../Elements/OurProject';
// import Blogs from './../Elements/Blogs';
// import Testimonials from './../Elements/Testimonials';
import OurStory from '../Elements/OurStory';
import Team2 from '../Elements/Team2';
import OurProject4 from '../Elements/OurProject4';
import ProjectMasonary from './ProjectMasonary';
import Slider from '../Elements/Slider';
import AboutSummary from '../Elements/AboutSummary';


class MacktodHome extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Slider/>
                    {/* <About /> */}
                    {/* <OurStory/> */}
                    <AboutSummary/>
                    <Team2/>
                    <OurProject4/>
                    {/* <ProjectMasonary /> */}
                    {/* <OurServices /> */}


                    {/* <OurValue /> */}
                    <OurMission />
                    {/* <Callus />
                    <Blogs />
                    <Testimonials /> */}
                </div>
                <Footer />
            </>
        );
    };
};

export default MacktodHome;