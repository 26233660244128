import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
// import About4 from './../Elements/About4';
// import Specialization4 from './../Elements/Specialization4';
// import Team3 from './../Elements/Team3';
import AboutSummary from './../Elements/AboutSummary';
import AboutCompany from '../Elements/AboutCompany';
import OurValue from '../Elements/OurValue';
import ClientsLogo from '../Elements/ClientsLogo';

var bnrimg = require('./../../images/banner/b1.png');

class MacktodAbout extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner title="Creating quality urban lifestyles, building stronger communities." pagename="About Us" bgimage={bnrimg}/>
                    <AboutCompany/>
                    <AboutSummary />
                    <OurValue/>


                    {/* <About4 />
                    <Specialization4 />
                    <Team3 />
                    <AboutSummary /> */}
                    <ClientsLogo/>
                </div>

                <Footer />

            </>
        );
    };
};

export default MacktodAbout;