import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
// import SimilarProjects from './../Elements/SimilarProjects';
import Banner from '../Elements/Banner';
import ReactPlayer from 'react-player';
import ProjectMasonary from './ProjectMasonary';

var bnrimg = require('./../../images/banner/b3.png');

class MacktodAchievements extends React.Component {
    
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Achievements & Certifications" pagename="Achievements" bgimage={bnrimg}/>
                    <ProjectMasonary/>   
                </div>
                <Footer />
            </>
        );
    };
};

export default MacktodAchievements;