import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const images = [
    require('./../../images/gallery/portrait/director 1.png'),
    require('./../../images/gallery/portrait/director 2.jpeg'),
    // require('./../../images/gallery/portrait/pic6.jpg'),
    // require('./../../images/gallery/portrait/pic7.jpg'),
    // require('./../../images/gallery/portrait/pic1.jpg'),
]

var bnr1 = require('./../../images/background/line.png');

class About4 extends React.Component {
    
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/masonary.js');
      
    };
    
    render() {
        const options = {
            loop:true,
            autoplay:true,
            autoplayTimeout:3000,
            margin:30,
            nav:false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items:1,
            dots: true
        };
        return (
            <>
                <div className="section-full p-t80 p-b80 bg-white inner-page-padding">
                    <div className="container">
                        <div className="section-content ">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 ">
                                    <div className="m-about ">
                                        {/* <OwlCarousel className="owl-carousel about-us-carousel" {...options}> */}
                                            {/* {images.map((item, index) => ( */}
                                                {/* <div className="item" key={index}> */}
                                                    <div className="ow-img">
                                                    <NavLink to="/about"><img src={ require('./../../images/gallery/portrait/director 1.png')} alt=""/></NavLink>
                                                    </div>
                                                {/* </div> */}
                                            {/* ))} */}
                                        
                                        {/* </OwlCarousel> */}                                       
                                    </div>
                                    <div className="author-name">
                                                        <h4 className="m-t0">Nadeem Aleem Khan </h4>
                                                        <p>CEO </p>
                                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <div className="m-about-containt text-black ">
                                        {/* <div className="m-about-years bg-moving" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                                            <span className="text-primary large-title">25</span>
                                            <span className="large-title-info">Years of Experience</span>
                                        </div> */}
                                        {/* <h3 className="font-weight-600">Improving quality of life with an integrated unified approach.</h3> */}
                                        <h4>Dear valued clients and partners,</h4>
                                        <p>As the CEO  of MAAKTOD, I am proud to welcome you to our website. Our parent companies have been providing top-quality construction services to clients all over the world for many years, and I am thrilled to continue this tradition of excellence.</p>
                                        <p>We believe that every project is unique, and we work closely with our clients to understand their specific needs and goals. Our team of experienced professionals uses the latest technology and innovative solutions to ensure that each project is completed on time, within budget, and to the highest standards.</p>
                                        <p>At MAAKTOD, we are committed to sustainability and to minimizing our impact on the environment. Our projects are designed to be energy-efficient and sustainable, and we use materials and processes that are environmentally friendly.</p>
                                        <p>We are grateful for the trust that our clients have placed in us, and we are dedicated to delivering exceptional service on every project. If you have any questions or would like to learn more about what we can do for you, please don't hesitate to contact us.</p>
                                        <p>Thank you for considering MAAKTOD for your construction needs.</p>
                                        <div className="author-info p-t20">
                                            {/* <div className="author-signature">
                                                <img src={require('./../../images/Signature+Black.png')} alt="Signature" width={150} />
                                            </div> */}
                                            {/* <div className="author-name">
                                                <h4 className="m-t0">Nadeem Aleem Khan </h4>
                                                <p>CEO </p>
                                            </div> */}
                                            {/* <NavLink to="/about" className="site-button btn-effect m-b15"><span>Get in touch</span></NavLink> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 ">
                                    <div className="m-about ">
                                        {/* <OwlCarousel className="owl-carousel about-us-carousel" {...options}> */}
                                            {/* {images.map((item, index) => ( */}
                                                {/* <div className="item" key={index}> */}
                                                    <div className="ow-img">
                                                    <NavLink to="/about"><img src={ require('./../../images/gallery/portrait/claudiaopacity.png')} alt=""/></NavLink>
                                                    </div>
                                                    
                                                {/* </div> */}
                                            {/* ))} */}
                                        
                                        {/* </OwlCarousel> */}                                       
                                    </div>
                                    <div className="author-name">
                                                        <h4 className="m-t0">Claudio Piccinin</h4>
                                                        <p>Director </p>
                                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default About4;