import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MacktodHome from './Pages/MacktodHome';
import MacktodAbout from './Pages/MacktodAbout';
import MacktodServices from './Pages/MacktodServices';
import MacktodProjects from './Pages/MacktodProjects';
import MacktodDirector from './Pages/MacktodDirector';
import MacktodRoad2 from './Pages/MacktodRoad2';
import MacktodRoad1 from './Pages/MacktodRoad1.jsx';
// import Home from './Pages/Home';
// import Home2 from './Pages/Home2';
// import Home3 from './Pages/Home3';
// import Home4 from './Pages/Home4';
// import About from './Pages/About';
// import Services from './Pages/Services';
// import BlogGrid from './Pages/BlogGrid';
// import BlogListing from './Pages/BlogListing';
// import BlogMasonary from './Pages/BlogMasonary';
// import Faq from './Pages/Faq';
import Error from './Pages/Error';
// import ContactUs from './Pages/ContactUs';
// import PostImage from './Pages/PostImage';
// import PostGallery from './Pages/PostGallery';
// import PostVideo from './Pages/PostVideo';
// import PostSidebar from './Pages/PostSidebar';

// import ProjectCarousel from './Pages/ProjectCarousel';
// import ProjectClassic1 from './Pages/ProjectClassic1';
// import ProjectClassic2 from './Pages/ProjectClassic2';
// import ProjectDetail from './Pages/ProjectDetail';
// import ProjectGrid from './Pages/ProjectGrid';
// import ProjectMasonary from './Pages/ProjectMasonary';

// import Accordian from './Pages/Accordian';
// import Button from './Pages/Button';
// import IconBoxStyle from './Pages/IconBoxStyle';
// import IconFont from './Pages/IconFont';
// import ListGroup from './Pages/ListGroup';
// import ModalPopup from './Pages/ModalPopup';
// import Table from './Pages/Table';
// import Tabs from './Pages/Tabs';
// import Video from './Pages/Video';
import ScrollToTop from './Common/ScrollToTop';
import MacktodRoad3 from './Pages/MacktodRoad3';
import MacktodRoad4 from './Pages/MacktodRoad4';
import MacktodBuilding1 from './Pages/MacktodBuilding1';
import MacktodBuilding2 from './Pages/MacktodBuilding2';
import MacktodBuilding3 from './Pages/MacktodBuilding3';
import MacktodBuilding4 from './Pages/MacktodBuilding4';
import MacktodBuilding5 from './Pages/MacktodBuilding5';
import MacktodBridges1 from './Pages/MacktodBridges1';
import MacktodBridges2 from './Pages/MacktodBridges3';
import MacktodBridges3 from './Pages/MacktodBridges2';
import MacktodDi1 from './Pages/MacktodDi1';
import MacktodDi2 from './Pages/MacktodDi2';
import MacktodAirandrail1 from './Pages/MacktodAirandrail1';
import MacktodAirandrail2 from './Pages/MacktodAirandrail2';
import MacktodAirandrail3 from './Pages/MacktodAirandrail3';
import CurrentProject from './Pages/CurrentProject1';
import MacktodAchievements from './Pages/MacktodAchievements';
import Housing1 from './Pages/Housing1';
import Housing2 from './Pages/Housing2';
import CurrentProjects from './Pages/CurrentProjects';
import CurrentProject1 from './Pages/CurrentProject1';
import CurrentProject2 from './Pages/CurrentProject2';
import CurrentProject3 from './Pages/CurrentProject3';
import CurrentProject4 from './Pages/CurrentProject4';


class Components extends React.Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <ScrollToTop />
                <div className="page-wraper">
                        <Routes>
                            {/* <Route path="/"  element={<Home/>} /> */}
                            {/* <Route path='/homepage2' element={<Home2/>} /> */}
                            {/* <Route path='/homepage3' element={<Home3/>} /> */}
                            {/* <Route path='/homepage4' element={<Home4/>} /> */}

                            {/* <Route path='/about' element={<About/>} /> */}
                            {/* <Route path='/services' element={<Services/>} /> */}

                            {/* <Route path='/blog-grid' element={<BlogGrid/>} /> */}
                            {/* <Route path='/blog-listing' element={<BlogListing/>} /> */}
                            {/* <Route path='/blog-masonary' element={<BlogMasonary/>} /> */}
                            {/* <Route path='/faq' element={<Faq/>} /> */}
                            {/* <Route path='/error' element={<Error/>} /> */}
                            {/* <Route path='/contactus' element={<ContactUs/>} /> */}

                            {/* <Route path='/post-image' element={<PostImage/>} /> */}
                            {/* <Route path='/post-gallery' element={<PostGallery/>} /> */}
                            {/* <Route path='/post-video' element={<PostVideo/>} /> */}
                            {/* <Route path='/post-sidebar' element={<PostSidebar/>} /> */}

                            {/* <Route path='/project-carousel' element={<ProjectCarousel/>} /> */}
                            {/* <Route path='/project-classic1' element={<ProjectClassic1/>} /> */}
                            {/* <Route path='/project-classic2' element={<ProjectClassic2/>} /> */}
                            {/* <Route path='/project-detail' element={<ProjectDetail/>} /> */}
                            {/* <Route path='/project-grid' element={<ProjectGrid/>} /> */}
                            {/* <Route path='/project-masonary' element={<ProjectMasonary/>} /> */}

                            {/* <Route path='/accordian' element={<Accordian/>} /> */}
                            {/* <Route path='/button' element={<Button/>} /> */}
                            {/* <Route path='/iconboxstyle' element={<IconBoxStyle/>} /> */}
                            {/* <Route path='/iconfont' element={<IconFont/>} /> */}
                            {/* <Route path='/listgroup' element={<ListGroup/>} /> */}
                            {/* <Route path='/modalpopup' element={<ModalPopup/>} /> */}
                            {/* <Route path='/table' element={<Table/>} /> */}
                            {/* <Route path='/tabs' element={<Tabs/>} /> */}
                            {/* <Route path='/video' element={<Video/>} /> */}
                             <Route path="/"  element={<MacktodHome/>} />
                                <Route path='/about' element={<MacktodAbout/>} />
                                <Route path='/services' element={<MacktodServices/>} />
                                <Route path='/team' element={<MacktodDirector/>} />
                                <Route path='/projects' element={<MacktodProjects/>} />
                                <Route path='/currentProjects' element={<CurrentProjects/>} /> 
                                <Route path='/currentProject1' element={<CurrentProject1/>} />
                                <Route path='/currentProject2' element={<CurrentProject2/>} />
                                <Route path='/currentProject3' element={<CurrentProject3/>} />
                                <Route path='/currentProject4' element={<CurrentProject4/>} />
                                <Route path='/achievements' element={<MacktodAchievements/>} />
                                <Route path='/road2' element={<MacktodRoad2/>} />
                                <Route path='/road1' element={<MacktodRoad1/>} />
                                <Route path='/road3' element={<MacktodRoad3/>} />
                                <Route path='/road4' element={<MacktodRoad4/>} />
                                <Route path='/building1' element={<MacktodBuilding1/>} />
                                <Route path='/building2' element={<MacktodBuilding2/>} />
                                <Route path='/building3' element={<MacktodBuilding3/>} />
                                <Route path='/building4' element={<MacktodBuilding4/>} />
                                <Route path='/building5' element={<MacktodBuilding5/>} />
                                <Route path='/bridges1' element={<MacktodBridges1/>} />
                                <Route path='/bridges2' element={<MacktodBridges2/>} />
                                <Route path='/bridges3' element={<MacktodBridges3/>} /> 
                                <Route path='/d&i2' element={<MacktodDi2/>} /> 
                                <Route path='/d&i1' element={<MacktodDi1/>} /> 
                                <Route path='/air&rail1' element={<MacktodAirandrail1/>} /> 
                                <Route path='/air&rail2' element={<MacktodAirandrail2/>} /> 
                                <Route path='/air&rail3' element={<MacktodAirandrail3/>} /> 
                                <Route path='/housing1' element={<Housing1/>} /> 
                                <Route path='/housing2' element={<Housing2/>} /> 
                            <Route element={<Error/>} />
                        </Routes>
                </div>
            </BrowserRouter>
        );
    };
};

export default Components;