import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutCompany extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left" style={{maxWidth:"75%",marginBottom:'10px',textAlign:'center'}}>
                                            <div className="mt-media" >
                                                {/* <img src={require('./../../images/s-1.png')} alt=""/> */}
                                                <img src={require('./../../images/maaktod.png')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">MAAKTOD</h3>
                                            <p>MAAKTOD is a joint venture between two well-established construction companies, MAAKSONS and TODINI. With a combined experience of over 300 years in the construction industry, we bring together the best of both worlds - the expertise and attention to detail of a local company, and the technical know-how and innovative solutions of an international firm. Our goal is to provide our clients with top-quality construction services that are tailored to their specific needs and that meet the highest standards of quality, safety, and sustainability. We are excited to bring our unique blend of expertise and experience to the table and to deliver exceptional results on every project.</p>
                                            {/* <div className="call-for-quote-outer">
                                                <div className="call-quote">
                                                    <span>Call For a Quote:</span>
                                                    <h4>(321) 456 6789</h4>
                                                </div>
                                                <div className="call-estimate bg-dark">
                                                <NavLink to={"#"} className="site-button-secondry btn-effect">Online Estimate Form</NavLink>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutCompany;