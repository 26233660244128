import React from 'react';
import { NavLink } from 'react-router-dom';

const filters = [
    { label: "Infrastructure", filter: ".cat-1" },
    { label: "Buildings", filter: ".cat-2" },
    { label: "Housing", filter: ".cat-3" },
    { label: "Dams & Irrigation", filter: ".cat-4" },
    { label: "Airports & Railways", filter: ".cat-5" },
    
];

const projects = [
    {
        image: require('./../../images/projects/portrait/Road Network Rehabilitation (500 km), Kazakhstan.png'),
        image2: require('./../../images/projects/portrait/Road Network Rehabilitation (500 km), Kazakhstan.png'),
        title: 'Road Network Rehabilitation (500 km), Kazakhstan',
        description: 'Client: Ministry of Transport and Communication – Republic of Kazakhstan',
        filter: 'cat-1',
        url:'/road1'
    },
    {
        image: require('./../../images/projects/portrait/National Road “CARLO FELICE”, Italy.png'),
        image2: require('./../../images/projects/portrait/National Road “CARLO FELICE”, Italy.png'),
        title: 'National Road “CARLO FELICE” , Italy',
        description: 'Client: ANAS - Italy',
        filter: 'cat-1',
        url:'/road2'
    },
    {
        image: require('./../../images/projects/portrait/Signal Free Corridor - Islamabad Expressway.png'),
        image2: require('./../../images/projects/portrait/Signal Free Corridor - Islamabad Expressway.png'),
        title: 'Signal Free Corridor - Islamabad Expressway ',
        description: 'Client: Capital Development Authority, Islamabad',
        filter: 'cat-1',
        url:'/road3'
    },
    {
        image: require('./../../images/projects/portrait/4.Bus Rapid Transit System (BRTS).png'),
        image2: require('./../../images/projects/portrait/4.Bus Rapid Transit System (BRTS).png'),
        title: 'Bus Rapid Transit System (BRTS)',
        description: 'Client: TEPA / LDA',
        filter: 'cat-1',
        url:'/road4'
    },
    {
        image: require('./../../images/projects/portrait/Ministry of Welfare Complex – Greece.png'),
        image2: require('./../../images/projects/portrait/Ministry of Welfare Complex – Greece.png'),
        title: 'Ministry of Welfare Complex – Greece',
        description: 'Client: Olympic Village 2004 SA – Athens',
        filter: 'cat-2',
        url:'/building1'
    },
    {
        image: require('./../../images/projects/portrait/Penta Square, DHA Phase V, Lahore.png'),
        image2: require('./../../images/projects/portrait/Penta Square, DHA Phase V, Lahore.png'),
        title: 'Penta Square, DHA Phase V, Lahore',
        description: 'Client: DHA Lahore',
        filter: 'cat-2',
        url:'/building2'
    },
    {
        image: require('./../../images/projects/portrait/Defence Raya Golf & Country Club, DHA Phase VI, Lahore.png'),
        image2: require('./../../images/projects/portrait/Defence Raya Golf & Country Club, DHA Phase VI, Lahore.png'),
        title: 'Defence Raya Golf & Country Club, DHA Phase VI, Lahore',
        description: 'Client: DHA Lahore',
        filter: 'cat-2',
        url:'/building3'
    },
    {
        image: require('./../../images/projects/portrait/corporate -7.png'),
        image2: require('./../../images/projects/portrait/corporate -7.png'),
        title: 'Corporate-7',
        description: 'Client: Gulberg Greens, Islamabad',
        filter: 'cat-2',
        url:'/building4'
    },
    {
        image: require('./../../images/projects/portrait/Diplomatic Enclave, Islamabad.png'),
        image2: require('./../../images/projects/portrait/Diplomatic Enclave, Islamabad.png'),
        title: 'Diplomatic Enclave, Islamabad',
        description: 'Client: US Embassy, Islamabad',
        filter: 'cat-2',
        url:'/building5'
    },
    {
        image: require('./../../images/projects/portrait/Renovation of Bolshoy Ustinsky Bridge.png'),
        image2: require('./../../images/projects/portrait/Renovation of Bolshoy Ustinsky Bridge.png'),
        title: 'Renovation of Bolshoy Ustinsky Bridge',
        description: 'Client: Moscow City Hall (Russian Federation)',
        filter: 'cat-1',
        url:'/bridges1'
    },
    {
        image: require('./../../images/projects/portrait/BARI Taranto Railway Line.png'),
        image2: require('./../../images/projects/portrait/BARI Taranto Railway Line.png'),
        title: 'BARI – Taranto Railway Line',
        description: 'Client: FS – Italy',
        filter: 'cat-1',
        url:'/bridges2'
    },
    {
        image: require('./../../images/projects/portrait/Signal Free Corridor  DHA Lahore.png'),
        image2: require('./../../images/projects/portrait/Signal Free Corridor  DHA Lahore.png'),
        title: 'Signal Free Corridor – DHA Lahore',
        description: 'Client: Defence Housing Authority (DHA)',
        filter: 'cat-1',
        url:'/bridges3'
    },
    {
        image: require('./../../images/projects/portrait/Hydroelectric Plant Riva Del Garda.png'),
        image2: require('./../../images/projects/portrait/Hydroelectric Plant Riva Del Garda.png'),
        title: 'Hydroelectric Plant – Riva Del Garda',
        description:'SEA – Aeroport Exercises Company Spa – Milan',
        address: 'State Electric Power Company – Italy',
        filter: 'cat-4',
        url:'/d&i2'
    },
    {
    image: require('./../../images/projects/portrait/Kramis Dam Construction.png'),
    image2: require('./../../images/projects/portrait/Kramis Dam Construction.png'),
    title: 'Kramis Dam Construction',
    description:'Rome Airports',
    address: 'National Dams Agency – Republic of Algeria',
    filter: 'cat-4',
    url:'/d&i1'
    },
    {
        image: require('./../../images/projects/portrait/Leonardo Da Vinci Airport, Rome.png'),
        image2: require('./../../images/projects/portrait/Leonardo Da Vinci Airport, Rome.png'),
        title: 'Leonardo Da Vinci Airport, Rome',
        description: 'Rome Airports',
        filter: 'cat-5',
        url:'/air&rail1'
    },
    {
        image: require('./../../images/projects/portrait/Malpensa Airport.png'),
        image2: require('./../../images/projects/portrait/Malpensa Airport.png'),
        title: 'Malpensa Airport',
        description: 'SEA – Aeroport Exercises Company Spa – Milan',
        filter: 'cat-5',
        url:'/air&rail2'
    },
    {
        image: require('./../../images/projects/portrait/Bari Urban Railway Connection.png'),
        image2: require('./../../images/projects/portrait/Bari Urban Railway Connection.png'),
        title: 'Bari – Urban Railway Connection',
        description: 'Ferrotramviaria SpA – Italy',
        filter: 'cat-5',
        url:'/air&rail3'
    },
    {
        image: require('./../../images/projects/portrait/nova 1.png'),
        image2: require('./../../images/projects/portrait/nova 1.png'),
        title: 'Nova City',
        description: 'MPQ',
        filter: 'cat-3',
        url:'/housing1'
    },
    {
        image: require('./../../images/projects/portrait/garden square (4).png'),
        image2: require('./../../images/projects/portrait/garden square (4).png'),
        title: 'Garden Square',
        // description: 'We are so busy watching out for what\'s just ahead of us that we don\'t take the time to enjoy where we are.',
        filter: 'cat-3',
        url:'/housing2'
    }
]

class OurProject4 extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-gray mobile-page-padding">
                        <div className="container">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Projects</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            {/* PAGINATION START */}
                            <div className="filter-wrap p-b30 text-center">
                                <ul className="filter-navigation inline-navigation masonry-filter link-style  text-uppercase">
                                <li className="active"><NavLink to={"#"} data-filter="*" data-hover="All">All</NavLink></li>
                                {filters.map((item, index) => (
                                    <li key={index}><NavLink to={"#"} data-filter={item.filter} data-hover={item.label}>{item.label}</NavLink></li>
                                ))}
                                </ul>
                            </div>
                            {/* PAGINATION END */}
                            {/* GALLERY CONTENT START */}
                            <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                                {projects.map((item, index) => (
                                    <div key={index} className={`${item.filter} masonry-item col-md-4 col-sm-6 m-b30`}>
                                        <div className="image-effect-two hover-shadow">
                                            <img src={item.image} alt="" />
                                            <div className="figcaption">
                                                <h4 className="mt-tilte">{item.title}</h4>
                                                <p>{item.description}</p>
                                                <NavLink to={item.url} className="read-more site-button btn-effect">Read More</NavLink>
                                                <a className="mfp-link" href={item.image2}>
                                                    <i className="fa fa-arrows-alt" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* GALLERY CONTENT END */}
                            {/* <div className="m-t50 text-center">
                                <NavLink to={"#"} className="site-button btn-effect">Load More</NavLink>
                            </div> */}
                        </div>
                    </div>    
            </>
        );
    }
};

export default OurProject4;