import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import OurStory from '../Elements/OurStory';
import About4 from '../Elements/About4';
import Team2 from '../Elements/Team2';
import Group from '../Elements/Group';
import Managment from '../Elements/Managment';

class MacktodDirector extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <About4/>
                    {/* <OurStory/>                */}
                    <Group/>
                    <Team2/>
                    <Managment/>
                </div>
                <Footer />
            </>
        );
    };
};

export default MacktodDirector;